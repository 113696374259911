const initialState = {
  branches: [],
  selectedBranch: {},
  restaurant: {},
  user: {}
}

const branchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET-DATA":
      return {
        ...state,
        branches: action.payload.restaurant.branches,
        selectedBranch: action.payload.restaurant.branches[0],
        restaurant: action.payload.restaurant,
        user: action.payload.user
      }
    case "ADD-BRANCH":
      return {
        ...state,
        branches: [...state.branches, action.payload]
      }
    case "SELECT-BRANCH":
      return { ...state, selectedBranch: action.payload }
    case "UPDATE-RESTAURANT": {
      return {
        ...state,
        restaurant: action.payload,
      }
    }
    case "UPDATE-RESTAURANT-SETTING": {
      return {
        ...state,
        restaurant: {...state.restaurant, settings: action.payload}
      }
    }
    case "UPDATE-RESTAURANT-THEME": {
      return {
        ...state,
        restaurant: {...state.restaurant, theme: action.payload}
      }
    }
      
    case "UPDATE-BRANCH":
      let branchesArr = [...state.branches];

      for (var i = 0; i < branchesArr.length; i++) {
        if (branchesArr[i].id === action.payload.id) {
          branchesArr[i] = action.payload.data;
        }
      }
      return {
        ...state,
        branches: branchesArr,
      }
    case "UPDATE-BRANCHES": {
      const updatedSelectedBranch = action.payload.find(branch => branch.id === state.selectedBranch.id) ?? action.payload[0]
      return {
        ...state,
        branches: action.payload,
        selectedBranch: updatedSelectedBranch
      }
    }
      
    case "UPDATE-USER":
      return {
        ...state,
        user: {...state.user, first_name: action.payload.first_name, last_name: action.payload.last_name, email:action.payload.email, username:action.payload.username,
        userinfo:{...state.user.userinfo, dial_code: action.payload.dial_code, phone: action.payload.phone, is_permitter_to_return_order: action.payload.is_permitter_to_return_order}},
      }
    case "UPDATE-USER-PERMISSION":
      return {
        ...state,
        user: {...state.user, userinfo:{...state.user.userinfo, is_permitter_to_return_order: action.payload}},
      }
    default:
      return state
  }
}

export default branchesReducer
