import React from "react"
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import { connect } from "react-redux"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"
import { ContextLayout } from "../../../utility/context/Layout"
import { FormattedMessage } from "react-intl"

const handleNavigation = (e, path) => {
  e.preventDefault()
  if (path === '/login') localStorage.removeItem('token')
  history.push(path)
}

const  UserDropdown = props => {
  return (
    <DropdownMenu right>
      {/* <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/pages/profile")}
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/email/inbox")}
      >
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/todo/all")}
      >
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/chat")}
      >
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>*/}
      <DropdownItem tag="a" target="_blank" href="https://fooodi.com">
        <Icon.ShoppingBag size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Marketplace" /></span>
      </DropdownItem>
      <DropdownItem tag="a" href='#' onClick={e => handleNavigation(e, "/login")}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="Log Out" /></span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
    suggestions: [],
    username: ''
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }


  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })



  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right align-items-center">
        <ContextLayout.Consumer>
          {layoutContext => {
            return (
              <IntlContext.Consumer>
                {context => {
                  let langArr = {
                    "en": "English",
                    "sa": "العربية",
                  }
                  const handleLanguageChange = (locale, dir) => {
                    context.switchLanguage(locale)
                    layoutContext.switchDir(dir)
                  }
                  return (
                    <Dropdown
                      tag="li"
                      className="dropdown-language nav-item"
                      isOpen={this.state.langDropdown}
                      toggle={this.handleLangDropdown}
                      data-tour="language"
                    >
                      <DropdownToggle
                        tag="a"
                        className="nav-link"
                      >
                        <ReactCountryFlag
                          className="country-flag"
                          countryCode={
                            context.state.locale === "en"
                              ? "us"
                              : context.state.locale
                          }
                          svg
                        />
                        <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                          {langArr[context.state.locale]}
                        </span>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          tag="a"
                          onClick={e => handleLanguageChange("en", "ltr")}
                        >
                          <ReactCountryFlag className="country-flag" countryCode="us" svg />
                          <span className="ml-1">English</span>
                        </DropdownItem>
                        <DropdownItem
                          tag="a"
                          onClick={e => handleLanguageChange("sa", "rtl")}
                        >
                          <ReactCountryFlag className="country-flag" countryCode="sa" svg />
                          <span className="ml-1">العربية</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )
                }}
              </IntlContext.Consumer>
            )
          }}
        </ContextLayout.Consumer>


        {/* <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Explore Florinz..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={e => {
                this.setState({ navbarSearch: false })
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"]
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                  ? item.email
                                  : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                                ? item.date
                                : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                )
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput)
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation()
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }}
              />
            </div>
          </div>
        </NavItem> */}
        {/* <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="primary" className="badge-up">
              {" "}
              5{" "}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">5 New</h3>
                <span className="notification-title">App Notifications</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              <div className="d-flex justify-content-between">
                <Media className="d-flex align-items-start">
                  <Media left href="#">
                    <Icon.PlusSquare
                      className="font-medium-5 primary"
                      size={21}
                    />
                  </Media>
                  <Media body>
                    <Media heading className="primary media-heading" tag="h6">
                      You have new order!
                    </Media>
                    <p className="notification-text">
                      Are your going to meet me tonight?
                    </p>
                  </Media>
                  <small>
                    <time
                      className="media-meta"
                      dateTime="2015-06-11T18:29:20+08:00"
                    >
                      9 hours ago
                    </time>
                  </small>
                </Media>
              </div>
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle">Read all notifications</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown> */}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <Icon.User size={20}/>
            <div className="user-nav d-sm-flex d-none ml-1">              
              <span className="user-name text-bold-600">
                {this.props.user.first_name + " " + this.props.user.last_name || this.props.restaurant.name}
              </span>
              <span className="user-status">{this.props.restaurant.name || <FormattedMessage id="Available" />}</span>
            </div>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}


const mapStateToProps = state => {
  return {
    bookmarks: state.navbar,
    restaurant: state.branches.restaurant,
    user: state.branches.user
  }
}

export default connect(mapStateToProps, null)(
  NavbarUser
)

