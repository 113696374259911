import request from '../request';

export async function getUserRestaurants(params) {
    const token = localStorage.getItem('token')
    return request({
        method: 'post',
        url: 'restaurant/user-restaurants/',
        data: params,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function addNewBranch(params) {
    const token = localStorage.getItem('token')
    return request({
        method: 'post',
        url: 'branch/createnew/',
        data: params,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function getBranchesList() {
    const token = localStorage.getItem('token')
    return request({
        method: 'post',
        url: '/branch/user-branches/',
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

//set restaurant social links
export async function setRestaurantInfo(id, params) {
    const token = localStorage.getItem('token')
    return request({
        method: 'patch',
        url: `restaurant/${id}/`,
        data: params,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function setRestaurantSetting(params) {
    const token = localStorage.getItem('token')
    return request({
        method: 'patch',
        url: `/restaurant/settings/`,
        data: params,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function getEmailSettings() {
    const token = localStorage.getItem('token')

    return request({
        method: 'get',
        url: `/restaurant/email-gateway/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    })
}

export async function setEmailSetting(params) {
    const token = localStorage.getItem('token')
    return request({
        method: 'patch',
        url: `/restaurant/email-gateway/`,
        data: params,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}
export async function getPaymentTypesChoices() {
    const token = localStorage.getItem('token')

    return request({
        method: 'get',
        url: `menu/order/payment/choices/`,
        headers: {
            'Authorization': `Token ${token}`
        },
    })
}

export async function getDynamicButtons(id) {
    const token = localStorage.getItem('token')
    return request({
        method: 'get',
        url: `restaurant/qr/dynamic-btn/list/${id}`,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function createDynamicButton(params) {
    const token = localStorage.getItem('token')
    return request({
        method: 'post',
        url: 'restaurant/qr/dynamic-btn/create/',
        data: params,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function  editDynamicButton(id, params) {
    const token = localStorage.getItem('token')
    return request({
        method: 'patch',
        url: `restaurant/qr/dynamic-btn/${id}/`,
        data: params,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function deleteDynamicButton(id) {
    const token = localStorage.getItem('token')
    return request({
        method: 'delete',
        url: `restaurant/qr/dynamic-btn/${id}/`,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

export async function getWorkingShifts(id) {
    const token = localStorage.getItem('token')
    return request({
        method: 'get',
        url: `/restaurant/branch/working-shift/${id}/list/`,
        headers: {
            'Authorization': `Token  ${token}`
        },
    })
}

