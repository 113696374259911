import React from "react"
import ScrollToTop from "react-scroll-up"
import { Button, Row, Col } from "reactstrap"
import { ArrowUp } from "react-feather"
import classnames from "classnames"
import FloatingMenuButton from "../../../components/FloatingMenuButton"

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <p className="mb-0 clearfix">
        <span className="float-md-left d-block d-md-inline-block mt-25">
          COPYRIGHT © {new Date().getFullYear()}
          <a
            href="https://www.florinz.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Florinz,
          </a>
          All rights reserved
        </span>
      </p>
      <Row className="match-height" >
        <Col>
          <FloatingMenuButton/>
        </Col>        
        <Col style={{zIndex: 100}}>
          {props.hideScrollToTop === false ? (
            <ScrollToTop showUnder={160}>
              <Button color="primary" className="btn-icon scroll-top">
                <ArrowUp size={15} />
              </Button>
            </ScrollToTop>
          ) : null}
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
