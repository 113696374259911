import React from "react";
import {
  NavItem,
  NavLink,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "rc-slider/assets/index.css";
import "../../../assets/scss/plugins/extensions/slider.scss";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import {
  getBranchesList,
} from "../../../services/branches/service";
import {
  FormattedMessage,
  injectIntl,
  IntlShape,
} from "react-intl";

class NavbarBookmarks extends React.PureComponent {
  state = {
    modal: false,
    branchesModal: false,
    activeBranch: "Branches",
    threshold: 5,
  };

  componentDidMount() {
    this.fetchUserBranches();
  }

  fetchUserBranches = () => {
    getBranchesList()
      .then((response) => {
        if (response.data) {
          if(response?.data?.user?.userinfo?.type === '3') this.props.updateUserPermission(response?.data?.user?.userinfo.is_permitter_to_return_order)
          this.props.updateBranches(response.data.restaurant.branches);
          this.props.updateRestaurant(response.data.restaurant);
        }
      })
      .catch((e) => {});
  };

  toggleBranches = () => {
    this.setState({
      branchesModal: !this.state.branchesModal,
    });
  };

  activateBranch = (item) => {
    this.props.selectBranch(item);
    this.toggleBranches();
  };

  render() {
    let { sidebarVisibility, branches, intl } = this.props;

    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <ul className="nav navbar-nav bookmark-icons">
          <NavItem
          //className="nav-item d-none d-lg-block"
          >
            <NavLink onClick={() => this.toggleBranches()}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon.MapPin size={21} />
                <span style={{ marginLeft: 10, marginTop: 5 }}>
                  {branches?.selectedBranch?.name}
                </span>
              </div>
            </NavLink>
          </NavItem>
        </ul>
        <Modal
          isOpen={this.state.branchesModal}
          scrollable={true}
          className={"modal-lg"}
          toggle={() => this.toggleBranches()}
        >
          <ModalHeader toggle={() => this.toggleBranches()}>
            <FormattedMessage id="Select From Existed Branches" />
          </ModalHeader>
          <ModalBody>
            <Table
              responsive
              className="dashboard-table table-hover-animation mb-0 mt-1"
            >
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="BRANCH" />
                  </th>
                  <th>
                    <FormattedMessage id="CITY" />
                  </th>
                  <th>
                    <FormattedMessage id="ADDRESS" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {branches?.branches?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>{item.city}</td>
                    <td>{item.address}</td>
                    <td>
                      {branches?.selectedBranch?.id !== item.id && (
                        <Button
                          onClick={() => {
                            this.activateBranch(item);
                          }}
                          size="sm"
                          color="flat-primary"
                        >
                          <FormattedMessage id="ACTIVATE" />
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggleBranches()}>
              <FormattedMessage id="Cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    branches: state.branches,
    user: state.branches.user.userinfo?.type,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBranches: (payload) =>
      dispatch({
        type: "UPDATE-BRANCHES",
        payload,
      }),
    updateRestaurant: (payload) =>
      dispatch({
        type: "UPDATE-RESTAURANT",
        payload,
      }),
    selectBranch: (payload) =>
      dispatch({
        type: "SELECT-BRANCH",
        payload,
      }),
      updateUserPermission: (payload) =>
      dispatch({
        type: "UPDATE-USER-PERMISSION",
        payload,
      }),
  };
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(NavbarBookmarks)
);
